<template>
  <div style="margin-bottom:36px">

    <!-- 最顶部 -->
    <div class="top">
      <el-carousel trigger="click" class="bnner">
        <el-carousel-item v-for="item in bnnerList" :key="item.title"
          :style="{ 'background-image': 'url(' + item.picture + ')' }">
          <div class="top-content">
            <div v-if="$i18n.locale != 'en-us'">{{ item.title }}</div>
            <div v-else>{{ item.titleEn }}</div>
            <div v-if="$i18n.locale != 'en-us'">{{ item.content }}</div>
            <div v-else>{{ item.contentEn }}</div>
            <div @click="gourl2(item.link)">
              <span v-if="$i18n.locale != 'en-us'">更多</span>
              <span v-else>more</span>
              <i class="el-icon-caret-right"></i>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>

      <!-- <el-carousel trigger="click" class="bnner">
                <el-carousel-item v-for="item in bnnerList" :key="item.id">
                    <div class="top-content">
                        <div v-if="$i18n.locale != 'en-us'">{{ item.title }}</div>
                        <div v-else>{{ item.titleEN }}</div>
                        <div v-if="$i18n.locale != 'en-us'">{{ item.introduce }}</div>
                        <div v-else>{{ item.introduceEN }}</div>
                        <div @click="gourl(item.url)">
                            <span v-if="$i18n.locale != 'en-us'">更多</span>
                            <span v-else>more</span>
                            <i class="el-icon-caret-right"></i>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel> -->
    </div>

    <!-- 探索 -->
    <div class="AiFossi">
      <div class="AiFossi-back">
        <p>EXPLORE</p>
      </div>
      <div class="AiFossi-center">
        <p v-if="$i18n.locale != 'en-us'">探索</p>
        <p v-else>EXPLORE</p>
        <div class="content">
          <img src="../../assets/image/home/tansuo.jpg" alt="">
          <div class="frame"></div>
          <div>
            <p>AiFossil</p>
            <p v-if="$i18n.locale != 'en-us'" style="line-height:36px">
              AI-Fossil 是一种基于图像自动识别化石的模型。该模型使用超过 40,000 张图像和深度学习的卷积神经网络算法进行训练。
            </p>
            <p v-else style="line-height:36px">
              AI-Fossil is a model of automated identifying fossils based on images. The model is trained
              using over 40,000 images and convolution neural network algorithm of deep learning.
            </p>
            <p style="cursor: pointer;" @click="gourl2('http://aifossil.fossil-ontology.com/#/')">
              <span v-if="$i18n.locale != 'en-us'">更多</span>
              <span v-else>more</span>
              <i class="el-icon-caret-right"></i>
            </p>
          </div>
        </div>
      </div>
    </div>


    <!-- 数据统计 -->
    <div class="data">
      <div class="data-all">
        <div class="map">
          <p v-if="$i18n.locale != 'en-us'">数据统计</p>
          <p v-else>data statistics</p>
          <div id="datafrom">
          </div>
        </div>
        <div class="base">
          <a class="single" href="http://fossil-ontology.com/home/list" target="_blank">
            <div>
              <p v-if="$i18n.locale != 'en-us'">化石图谱</p>
              <p v-else>Fossil map</p>
              <p v-if="$i18n.locale != 'en-us'">可视化描述生物化石之间的关系</p>
              <p v-else>Visually describe the relationship between biological fossils</p>
            </div>
            <i class="el-icon-caret-right"></i>
          </a>
          <a class="single" href="http://www.geobiodiversity.com/home" target="_blank">
            <div>
              <p>GBDB</p>
              <p v-if="$i18n.locale != 'en-us'">地球生物多样性数据</p>
              <p v-else>Earth's biodiversity data</p>
            </div>
            <i class="el-icon-caret-right"></i>
          </a>
          <a class="single" href="http://references.fossil-ontology.com/#/home/list" target="_blank">
            <div>
              <p v-if="$i18n.locale != 'en-us'">文献系统</p>
              <p v-else>DOCUMENT SYSTEM</p>
              <p v-if="$i18n.locale != 'en-us'">生物学专业数据库系统</p>
              <p v-else>Specialized database Systems for Biology</p>
            </div>
            <i class="el-icon-caret-right"></i>
          </a>

        </div>
      </div>

    </div>

    <!-- 事件 -->
    <div class="events">
      <div class="events-back">
        <p>EVENTS</p>
      </div>
      <div class="events-center">
        <p v-if="$i18n.locale != 'en-us'">事件</p>
        <p v-else>EVENTS</p>
        <div class="content" v-if="eventList[0]">
          <div class="left" v-if="eventList[0]" @click="gourl(eventList[0].id)">
            <img :src="eventList[0].cover" alt="">
            <p v-if="$i18n.locale != 'en-us'">{{ eventList[0].title }}</p>
            <p v-else>{{ eventList[0].title }}</p>
            <p v-if="$i18n.locale != 'en-us'">{{ eventList[0].profile }}</p>
            <p v-else>{{ eventList[0].profile }}</p>
          </div>

          <div class="right">
            <div v-for="item in eventList.slice(1, 5)" :key="item.id" class="single" @click="gourl(item.id)">
              <div class="time">
                <p>{{ (item.dateTime).slice(8, 10) }}</p>
                <p>{{ (item.dateTime).slice(0, 7) }}</p>
              </div>
              <div class="text">
                <p v-if="$i18n.locale != 'en-us'">{{ item.title }}</p>
                <p v-else>{{ item.title }}</p>
                <p v-if="$i18n.locale != 'en-us'">{{ item.profile }}</p>
                <p v-else>{{ item.profile }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 人物 -->
    <div class="figure">
      <div class="figure-back">
        <p>INTRODUCE</p>
      </div>
      <div class="figure-center">
        <p v-if="$i18n.locale != 'en-us'">人物介绍</p>
        <p v-else>INTRODUCE</p>
        <div class="swiper-container">
          <div class="swiper-wrapper content">
            <div v-for="item in figure" :key="item.name" class="swiper-slide single">
              <img :src="item.picture" alt="">
              <div>
                <p>{{ item.name }}</p>
                <p>{{ item.selfIntroduction }}</p>
                <p>{{ item.location }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="content">
                    <div v-for="item in figure" :key="item.name" class="single">
                        <img :src="item.picture" alt="">
                        <div>
                            <p>{{ item.name }}</p>
                            <p>{{ item.selfIntroduction }}</p>
                            <p>{{ item.location }}</p>
                        </div>
                    </div>
                </div> -->
      </div>

      <!-- <div class="figure-center">
                <p v-if="$i18n.locale != 'en-us'">人物介绍</p>
                <p v-else>INTRODUCE</p>
                <div class="content">
                    <div v-for="item in figure" :key="item.id" class="single">
                        <img src="../../assets/image/introduce/xuhehong.jpg" alt="" v-if="item.id == 0">
                        <img src="../../assets/image/introduce/zhanrenbin.jpg" alt="" v-if="item.id == 1">
                        <img src="../../assets/image/introduce/wangjun.jpg" alt="" v-if="item.id == 2">
                        <img src="../../assets/image/introduce/chenyuanshen.jpg" alt="" v-if="item.id == 3">
                        <div>
                            <p>{{ item.nameEN }}</p>
                            <p>{{ item.introduceEN }}</p>
                            <p>{{ item.addrEN }}</p>
                        </div>
                    </div>
                </div>
            </div> -->
    </div>

    <!-- 关于 -->
    <div class="footer">
      <div class="center">
        <div class="about">
          <p v-if="$i18n.locale != 'en-us'">关于我们</p>
          <p v-else>ABOUT us</p>
          <p v-if="$i18n.locale != 'en-us'">OneFossil是一个多维化石标本科学数据库，用于虚拟检查和数据驱动的地层和古生物学研究。这是对DDE古生物学工作组的贡献。
          </p>
          <p v-else>OneFossil is a multi-dimensional scientfhic database of fossil specimens for virtual
            examination
            and data-driven stratigraphicaland paleontological research. This is the contribution to the
            Paleontology Working Group of the DDE.</p>
          <div>
            <span>HOME</span><span>AIFOSSIL</span><span>RESEARCH</span><span>EVENTS</span><span>ABOUT</span>
          </div>
        </div>
        <div class="code">
          <div id="qrcode"></div>
          <span v-if="$i18n.locale != 'en-us'">手机扫描查看分享</span>
          <span v-else>Scan and share on your phone</span>
        </div>
      </div>

      <div class="bottom">
        &copy; Nanjing Institute of Geology and Palaeonotology.All Rights Reserved.
      </div>
    </div>

  </div>
</template>

<script>
import ImageApi from '@/api/image/index';
import HomeApi from '../../api/home/index'
import QRCode from 'qrcodejs2';
import * as echarts from 'echarts';

import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
import Swiper from 'swiper'
import { newlist } from '@/api/news/index'

export default {
  data () {
    return {
      eventList: [
        {
          id: 0,
          title: "第十届中国(湖南)国际矿业博览会在郴州成功举办",
          titleEN: "The 10th China (Hunan) International Mining Expo was successfully held in Chenzhou",
          content: "2022年7月8日至12日，第十届中国(湖南)国际矿产宝石交易会在湖南省郴州市成功举办。本次展览充分发挥中国古生物学会在地质古生物学领域和国内外科普活动领域的特色优势，以聚焦矿物晶体宝石背后的科学内涵、揭开矿物晶体形成之谜为特色主题，为专业化、国际化和科学传播增添色彩。",
          contentEN: "The 10th China (Hunan) International Mineral and Gem Fair was successfully held in Chenzhou City, Hunan Province from July 8-12, 2022. This exhibition gives full play to the characteristic advantages of the Palaeontological Society of China in the field of geological palaeontology and science popularization activities at home and abroad, focuses on the scientific connotation behind mineral crystal gems and unveils the mystery of mineral crystal formation as the characteristic theme, and helps to add color in specialization",
          time: "2022-07-19",
          url: "http://english.nigpas.cas.cn/new/hs/icn/202209/t20220926_320761.html"
        },
        {
          id: 1,
          title: "国际古生物学、高分辨率地层学和化石能源会议暨尼格帕斯70周年庆祝活动在南京举行",
          titleEN: "The International Conference on Palaeobiology, High Resolution Stratigraphy and Fossil Energy -with celebrations on the 70th Anniversary of NIGPAS was held in Nanjing",
          content: "为庆祝中国科学院南京地质古生物研究所成立70周年，“古生物学、高分辨率地层学与化石能源国际会议”于2021年11月18日至19日在南京举行。由于COVID-19大流行，会议以线上线下同步的学术研讨会形式举行。",
          contentEN: "To celebrate the 70 th Anniversary of Nanjing Institute of Geology and Palaeontology, Chinese Academy of Sciences (NIGPAS), the International Conference on Palaeobiology, High Resolution Stratigraphy and Fossil Energy was held in Nanjing during November 18-19, 2021. The conference was conducted in a simultaneous online and offline academic seminar format because of the COVID-19 pandemic.",
          time: "2021-11-20",
          url: "http://english.nigpas.cas.cn/new/hs/icn/202209/t20220926_320762.html"
        },
        {
          id: 2,
          title: "国际会议的古生物学，高分辨率地层学和化石能源",
          titleEN: "International Conference on Palaeobiology, High Resolution Stratigraphy and Fossil Energy",
          content: "为庆祝中国科学院南京地质古生物研究所成立70周年，“古生物学、高分辨率地层学与化石能源国际会议”将于2021年11月18日至19日在南京举行。组委会诚邀您参加本次盛会。会议将集中讨论古生物学、地层学、化石能源和其他有关领域的最新进展和前景。",
          contentEN: "To celebrate the 70th Anniversary of Nanjing Institute of Geology and Palaeontology, Chinese Academy of Sciences (NIGPAS), the International Conference on Palaeobiology, High Resolution Stratigraphy and Fossil Energy will be held in Nanjing during 18-19 November 2021. The Organizing Committee cordially invites you to join us for this big convention. The conference will concentrate on recent advances and perspectives in the fields of palaeobiology, stratigraphy, fossil energy and other relevant areas. ",
          time: "2021-11-20",
          url: "http://english.nigpas.cas.cn/new/hs/icn/202207/t20220725_309359.html"
        },
        {
          id: 3,
          title: "IGCP 679年会2019在中国青岛举行",
          titleEN: "IGCP 679 Annual Meeting 2019 was held in Qingdao, China",
          content: "2019年10月11 - 17日，国际地球科学项目IGCP 679项目“白垩纪地球动力学与亚洲古气候”首次国际研讨会在山东青岛举行。会议由国家地质调查局主办，山东省地质调查院、山东科技大学地球科学与工程学院承办，青岛地质工程调查院协办。会议包括2天的室内学术报告和交流，以及3天的会前野外地质调查。",
          contentEN: "On October 11 - 17, 2019, the first international symposium of the International Geoscience Program IGCP 679 project 'Cretaceous Geodynamics and Asian Paleoclimate' was held in Qingdao, Shandong. The conference was hosted by the NIGPAS, undertaken by the Shandong Institute of Geological Survey and the College of Earth Science and Engineering, Shandong University of Science and Technology, and co-organized by the Qingdao Institute of Geological Engineering Survey. The meeting included 2 days of indoor academic reports and exchanges, and 3-days of pre-conference field geological survey.",
          time: "2020-11-18",
          url: "http://english.nigpas.cas.cn/new/hs/icn/202207/t20220725_309358.html"
        },
        {
          id: 4,
          title: "首届“跨国界知识”网络研讨会",
          titleEN: "The first webinar 'Knowledge Across Borders'",
          content: "为促进中国科学院与英国杜伦大学的双边合作，探索新冠肺炎疫情期间国际科技合作的新途径，2020年10月29日，中国科学院与英国杜伦大学联合举办“跨国界知识”网络讲座。",
          contentEN: "In order to promote bilateral cooperation between the Chinese Academy of Sciences and Durham University in the United Kingdom, and explore new ways of international scientific and technological cooperation during the epidemic, on October 29, 2020, the Chinese Academy of Sciences and Durham University launched web lectures of 'Knowledge Across Borders'.",
          time: "2020-11-02",
          url: "http://english.nigpas.cas.cn/new/hs/icn/202207/t20220725_309357.html"
        },
      ],
      figure: [
      ],
      bnnerList: [
        {
          id: 0,
          title: "化石标本图像综合数据库",
          titleEN: "A multi-dimensional scientific database of fossil specimens",
          image: "https://tse1-mm.cn.bing.net/th/id/OIP-C.tV58EvgJSzLG3iOTLzB85QHaEo?pid=ImgDet&rs=1",
          introduce: "化石标本图像综合数据库 是一个古生物化石标本多维科学数据库，可用于化石标本虚拟展示和数据驱动的地层和古生物学研究。",
          introduceEN: "A multi-dimensional scientific database of fossil specimens for virtual examination and data-driven stratigraphical and paleontological research.",
          url: "http://fossil-ontology.com/home/list"
        },
        {
          id: 1,
          title: "地理生物多样性数据库",
          titleEN: "Geobiodiversity Database",
          image: "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Flmg.jj20.com%2Fup%2Fallimg%2F1114%2F121420113514%2F201214113514-6-1200.jpg&refer=http%3A%2F%2Flmg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1669946033&t=b1ae6d8c149bd7563bd261ffa883b3c2",
          introduce: "地理生物多样性数据库，一个管理和分析地层和古生物信息的综合系统",
          introduceEN: "The Geobiodiversity Database, an integrated system for the management and analysis of stratigraphic and paleontological information",
          url: "http://www.geobiodiversity.com/"
        },
        {
          id: 2,
          title: "古生物文献数据库",
          titleEN: "Paleontological database",
          image: "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Flmg.jj20.com%2Fup%2Fallimg%2F1114%2F0406210Z024%2F2104060Z024-11-1200.jpg&refer=http%3A%2F%2Flmg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1669946033&t=ec338ecbea28ae131622c24575deacac",
          introduce: "以古生物学和地层学领域的科研成果为核心、搜集、汇总并梳理科研成果中的作者与单位、地质信息、化石记录、数据、资助情况等五项元素建立科研成果信息化综合数据库。",
          introduceEN: "With the scientific research achievements in the field of paleontology and stratigraphy as the core, five elements such as author and unit, geological information, fossil record, data and funding in the scientific research achievements are collected, summarized and sorted out to establish an informatization comprehensive database of scientific research achievements.",
          url: "http://references.fossil-ontology.com/#/home/list"
        }
      ],
      xData: [],
      xDataEN: [],
      yData: []

    }
  },
  mounted () {
    HomeApi.gitsystem(1).then(res => {
      this.bnnerList = res.data.slideshow
      this.bnnerList[0].url = res.data.picRecognitionUrl
      this.bnnerList[1].url = res.data.gbdbSearchUrl
      this.bnnerList[2].url = res.data.referencesSearch
      this.figure = res.data.teamIntroduction

      setTimeout(() => {
        // 人物介绍轮播初始化
        new Swiper('.swiper-container', {
          direction: 'horizontal', // 垂直切换选项
          mousewheel: true, //滚轮
          loop: true, // 设置为true 则开启loop模式
          slidesPerView: 4, // 设置slider容器能够同时显示的slides数量(carousel模式)。类型：number or auto
          // centeredSlides: true, // 设定为true时，active slide会居中，而不是默认状态下的居左。
          // spaceBetween: 20, // 在slide之间设置距离（单位px）。
          loopAdditionaSlider: 0, // loop模式下会在slides前后复制若干个slide,，前后复制的个数不会大于原总个数。
          autoplay: {
            //自动开始
            delay: 5000, //时间间隔
            disableOnInteraction: false, //*手动操作轮播图后不会暂停*
          },
          loop: true, // 循环模式选项
        })
      }, 200)
    })
     newlist(this.searchform).then((res) => {
        this.eventList = res.data.records;
      });
    this.$nextTick(() => {
      this.qrcode = new QRCode("qrcode", {
        width: 90,
        height: 100, // 高度
        text: "默认内容", // 二维码内容
        // colorDark: "#23420f",
      });
    });




    this.getData()

  },
  updated () {
    this.getData()
  },
  methods: {
    //获取图表数据
    getData () {
      //请求接口
      ImageApi.dataStatistics().then((res) => {
        if (res.code == "200") {
          this.initdata(res.data)
        }
      });
    },
    //初始化图表
    pillarsEcharts (x, y, y2) {

      //获取id并初始化图表
      var myChart = echarts.init(document.getElementById("datafrom"));
      // myChart.clear(); //销毁
      let option = {
        tooltip: {
          //鼠标触摸显示值
          trigger: "axis",
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: this.$i18n.locale == 'en-us' ? '20%' : '10%' //中英文名称长度不同所做的兼容
        },
        xAxis: {
          //x轴设置
          axisLine: { show: false },//不显示坐标轴
          axisTick: {
            show: false,//不显示坐标轴刻度线
          },
          splitLine: {
            show: false
          },
          show: false,
          type: "value", //类型
          data: x, //x即接口方法传递过来的参数也是x轴的数据（
        },
        yAxis: {
          //y轴设置
          type: "category", //类型
          axisLine: { show: false },//不显示坐标轴
          axisTick: {
            show: false,//不显示坐标轴刻度线
          },
          splitLine: {
            show: false
          },
          nameLocation: "center",
          data: x,
          axisPointer: {
            label: {
              show: false
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: 'black',  //更改坐标轴文字颜色
              fontSize: 12,      //更改坐标轴文字大小
              fontWeight: 800,
            }
          },

        },
        series: [
          {
            data: y, //y即接口方法传递过来的参数也是y轴的数据
            type: "bar", //类型
            barWidth: 30,
            label: {
              show: true, // 显示值
              position: "right", // 显示位置
              color: "#000000",
            },
            itemStyle: {
              normal: {
                color: function (params) {
                  let colorList = [
                    "#9F5CD0",
                    "#C180FE",
                    "#80C0FE",
                    "#82FEE4",
                    "#AEFE81",
                    "#FFAC82",
                    "#FE8081",
                  ];
                  return colorList[params.dataIndex];
                }
              }
            }
          },
        ],
      };

      myChart.setOption(option); //通过setOption()方法生成图表
      window.addEventListener("resize", function () {
        myChart.resize(); //图表自适应的一个方法
      });
    },
    // 处理图片数据 （排序）
    initdata (data) {
      let that = this;
      var httpRequest = new XMLHttpRequest();
      httpRequest.open('GET', 'http://159.226.74.239:8058/search/searchAllNumForAuthority', true);
      httpRequest.send();
      httpRequest.onreadystatechange = function () {
        if (httpRequest.readyState == 4 && httpRequest.status == 200) {
          var json = JSON.parse(httpRequest.responseText);
          that.xData = []
          that.xDataEN = []
          that.yData = []

          // 文献统计
          if (json.result.reference) {
            that.xData.push('文献统计');
            that.xDataEN.push('reference')
            that.yData.push(json.result.reference)
          }

          if (json.result.section) {
            that.xData.push('剖面统计');
            that.xDataEN.push('section')
            that.yData.push(json.result.section)
          }

          if (json.result.fossil) {
            that.xData.push('化石产出');
            that.xDataEN.push('fossil')
            that.yData.push(json.result.fossil)
          }
          if (data.imageInfoNum) {
            that.xData.push('图像统计');
            that.xDataEN.push('imageInfoNum')
            that.yData.push(data.imageInfoNum)
          }

          if (data.model3DNum) {
            that.xData.push('3D模型');
            that.xDataEN.push('model3DNum')
            that.yData.push(data.model3DNum)
          }

          if (data.tomographyNum) {
            that.xData.push('断层扫描');
            that.xDataEN.push('tomographyNum')
            that.yData.push(data.tomographyNum)
          }

          if (data.panoramaNum) {
            that.xData.push('全景图');
            that.xDataEN.push('panoramaNum')
            that.yData.push(data.panoramaNum)
          }
          if (that.$i18n.locale == 'zh-cn') {
            that.pillarsEcharts(that.xData.reverse(), that.yData.reverse()); //调用图表方法并将参数（数据）传递过去
          } else {
            that.pillarsEcharts(that.xDataEN.reverse(), that.yData.reverse()); //调用图表方法并将参数（数据）传递过去
          }
        }
      };



    },
    gourl (id) {
      // window.open(url)
      this.$router.push({
        path: 'detail?id=' + id
      });
    },
    gourl2 (link) {
      window.open(link, '_blank')
    },
    goImage () {
      let routeUrl = this.$router.resolve({
        path: "/home/list",
      })

      window.open(routeUrl.href, '_blank')
    }
  },

}
</script>

<style lang="scss" scoped>
.top {
  width: 100%;
  height: 90vh;
  display: flex;
  // justify-content: center;
  align-items: center;
  position: relative;

  .bnner {
    height: 100%;
    width: 100%;


    // background-image: url('https://tse1-mm.cn.bing.net/th/id/OIP-C.tV58EvgJSzLG3iOTLzB85QHaEo?pid=ImgDet&rs=1');
    // background-size: 100% 100%;
    ::v-deep .el-carousel__button {
      width: 50px;
      height: 6px;
    }

    ::v-deep .el-carousel__container {
      height: 100% !important;

      .el-carousel__item {
        background-size: 100% 100%;
        display: flex;
        align-items: center;


        // background-image: url('../../assets/image/home/top.jpg');

        &:nth-child(3) {
          background-image: url('../../assets/image/home/bnner1.jpg');
        }

        &:nth-child(4) {
          background-image: url('../../assets/image/home/bnner2.jpg');
        }

        &:nth-child(5) {
          background-image: url('../../assets/image/home/bnner3.jpg');
        }
      }
    }
  }

  &-content {
    // position: absolute;
    width: 70%;
    height: 70%;
    margin-left: 15%;
    margin-top: 5%;
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;

    div {
      color: #FFFFFF;

      &:nth-child(1) {
        font-size: 60px;
        font-weight: 600;
      }

      &:nth-child(2) {
        font-size: 22px;
        font-weight: 600;
      }


      &:nth-child(3) {
        height: 30px;
        width: 70px;
        background-color: #4191E8;
        line-height: 30px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        cursor: pointer;
      }

      span {
        position: relative;
        top: -5%;
        font-weight: 800;
      }
    }
  }
}

.AiFossi {
  width: 100%;
  height: 90vh;
  position: relative;

  &-back {
    box-sizing: border-box;
    text-align: center;
    height: 100%;
    padding-top: 4%;

    p {
      font-size: 90px;
      font-weight: 800;
      color: #0055B0;
      opacity: .1;
    }


  }

  &-center {
    box-sizing: border-box;
    padding-top: 3.5%;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    align-items: center;

    p {

      font-size: 30px;
      font-weight: 800;
      // font-weight: normal;
    }

    .content {
      background-image: url('https://tse1-mm.cn.bing.net/th/id/OIP-C.tV58EvgJSzLG3iOTLzB85QHaEo?pid=ImgDet&rs=1');
      background-size: 100% 100%;
      height: 70%;
      width: 65%;
      display: flex;
      position: relative;

      .frame {
        position: absolute;
        box-sizing: border-box;
        width: 40%;
        height: 80%;
        margin: 0 auto;
        border: 14px solid #E67E47;
        background: #ffffff;
        z-index: -1;
        top: 10%;
        left: -10%;
      }

      img {
        width: 70%;
        height: 100%;
      }

      div {
        box-sizing: border-box;
        width: 30%;
        height: 100%;
        padding: 3%;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        background-color: #E67E47;
        color: #FFFFFF;

        p {
          font-size: 16px;

          &:nth-child(1) {
            font-size: 34px;
            font-weight: 800;
          }

          &:nth-child(3) {
            height: 30px;
            width: 70px;
            background-color: #FFFFFF;
            color: #E67E47;
            line-height: 30px;
            font-weight: 800;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
          }
        }

        span {
          position: relative;
          top: -5%;
          font-weight: 800;
        }
      }
    }
  }
}

.data {
  width: 100%;
  height: 90vh;
  background-image: url('../../assets/image/home/databack.jpg');
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &-all {
    width: 65%;
    height: 70%;
    display: flex;
    justify-content: space-between;

    .map {
      width: 60%;
      height: 100%;
      box-sizing: border-box;
      // padding: 2% 3%;
      background-color: #FFFFFF;
      position: relative;

      #datafrom,
      #datafrom2 {
        width: 95%;
        height: 110%;
        margin: 0 auto;
        font-weight: 800;
        font-size: 16px
      }

      p {
        position: absolute;
        font-size: 20px;
        font-weight: 800;
        position: absolute;
        left: 3%;
        top: 4%;
      }
    }

    .base {
      width: 35%;
      height: 100%;
      display: flex;
      flex-flow: column;
      justify-content: space-between;

      .single {
        height: 31%;
        width: 100%;
        // background-color: #E59C74;
        background-image: url('../../assets/image/home/back2.png');
        background-size: 100% 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #FFFFFF !important;
        cursor: pointer;

        &:nth-child(2) {
          // background-color: #729FF0;
          background-image: url('../../assets/image/home/back3.png');
        }

        &:nth-child(3) {
          // background-color: #43C8CF;
          background-image: url('../../assets/image/home/back1.png');
        }

        div {
          height: 60%;
          margin-left: 10%;
          display: flex;
          flex-flow: column;
          justify-content: space-between;

          p {
            &:nth-child(1) {
              font-size: 24px;
            }

            &:nth-child(2) {
              font-size: 16px;
            }
          }
        }

        i {
          margin-right: 5%;
          font-size: 24px;
          opacity: 0;
        }
      }
    }
  }
}

.events {
  width: 100%;
  height: 90vh;
  position: relative;
  background-color: #ECF5FA;

  &-back {
    box-sizing: border-box;
    text-align: center;
    height: 100%;
    padding-top: 2.5%;

    p {
      font-size: 80px;
      font-weight: 800;
      color: rgba(0, 0, 0, .1);
    }


  }

  &-center {
    box-sizing: border-box;
    padding-top: 1.5%;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    align-items: center;

    p {

      font-size: 30px;
      font-weight: 800;
    }

    .content {

      height: 70%;
      width: 65%;
      display: flex;

      .left {
        width: 50%;
        height: 100%;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        cursor: pointer;

        img {
          width: 95%;
          height: 70%;
        }

        p {
          width: 95%;
          font-size: 12px;
          color: #666666;
          display: -webkit-box;
          overflow: hidden;
          -webkit-box-orient: vertical;
          line-clamp: 2;

          &:nth-child(2) {
            font-size: 16px;
            color: #000000;
            font-weight: 800;
            -webkit-line-clamp: 1;

          }

          &:nth-child(3) {

            -webkit-line-clamp: 4; //显示几行
          }
        }
      }

      .right {
        box-sizing: border-box;
        width: 50%;
        height: 100%;
        padding-left: 3%;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        color: #FFFFFF;

        .single {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 2px solid #BBBBBB;
          padding-bottom: 2%;
          cursor: pointer;

          .time {
            p {
              &:nth-child(1) {
                font-size: 43px;
              }
            }
          }

          .text {
            height: 90%;
            margin-left: 2%;
            display: flex;
            flex-flow: column;
            justify-content: space-between;

            p {
              display: -webkit-box;
              overflow: hidden;
              -webkit-box-orient: vertical;
              line-clamp: 2;

              &:nth-child(1) {
                font-size: 14px;
                color: #000000;
                font-weight: 800;
                margin-top: 1.2%;

                -webkit-line-clamp: 1; //显示几行
              }

              &:nth-child(2) {

                -webkit-line-clamp: 2; //显示几行
              }
            }
          }

          p {
            font-size: 12px;
            color: #666666;
          }
        }

      }
    }
  }
}

.figure {
  width: 100%;
  height: 90vh;
  position: relative;
  background-color: #FFFFFF;

  &-back {
    box-sizing: border-box;
    text-align: center;
    height: 100%;
    padding-top: 2.5%;

    p {
      font-size: 80px;
      font-weight: 800;
      color: #0055B0;
      opacity: .1;
    }


  }

  &-center {
    box-sizing: border-box;
    padding-top: 3%;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
    align-items: center;

    p {

      font-size: 30px;
      font-weight: 800;
    }

    .content {
      margin: 0 auto;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;

      .single {
        width: 22% !important;
        height: 90%;
        display: flex;
        flex-flow: column;
        margin: 0 1.5%;
        cursor: pointer;

        img {
          width: 100%;
          height: 70%;
          object-fit: cover;
        }

        div {
          width: 100%;
          height: 30%;
          box-sizing: border-box;
          padding: 12px;
          display: flex;
          flex-flow: column;
          justify-content: space-between;
          border: 1px solid #BBBBBB;
          border-top: none;

          p {
            font-size: 12px;
            color: #000000;

            &:nth-child(1) {
              font-size: 16px;
              font-weight: 800;
            }

            &:nth-child(2) {
              display: -webkit-box;
              overflow: hidden;
              -webkit-box-orient: vertical;
              line-clamp: 2;
              -webkit-line-clamp: 3; //显示几行
            }
          }
        }


      }
    }

  }
}

.footer {
  width: 100%;
  height: 40vh;
  background-color: #000000;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;
  padding: 3.5% 10%;
  padding-bottom: 6%;
  color: #FFFFFF;
  margin-bottom: 36px;

  .center {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .about {
      height: 100%;
      display: flex;
      flex-flow: column;
      justify-content: space-between;

      p {
        font-size: 16px;

        &:nth-child(1) {
          font-size: 22px;
          font-weight: 800;
        }
      }

      span {
        margin-left: 9%;
        cursor: pointer;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    .code {
      // widows: ;
      height: 100%;
      text-align: center;
      display: flex;
      flex-flow: column;
      justify-content: space-between;

      // width: 30%;
      #qrcode {
        box-sizing: border-box;
        height: 110px;
        width: 110px;
        background-color: #FFFFFF;
        padding: 10px 14px;
        display: flex;
        justify-content: center;

        img {
          margin: 0 auto;
        }
      }

      span {
        display: block;
        font-size: 12px;
        cursor: pointer;
      }
    }

  }

  .bottom {
    margin-top: 2%;
    font-size: 12px;
    color: #BBBBBB;
  }
}


.swiper-container {
  width: 70%;
  margin: 0 auto;
  height: 80%;

}

.swiper-slide {
  width: auto;
  position: relative;

  .photo {
    display: flex;
    width: 350px;
    height: 465px;
    cursor: pointer;
    margin-right: 20px;
    flex-direction: column-reverse;
    background: linear-gradient(180deg,
        rgba(255, 255, 255, 0) 0%,
        #000000 100%);
    border-radius: 0px 0px 6px 6px;

    .title {
      font-size: 19px;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 16px;
      margin-left: 23px;
      text-align: left;
    }

    .content {
      font-weight: bold;
      color: #ffffff;
      font-size: 12px;
      margin-bottom: 24px;
      margin-left: 23px;
      width: 294px;
      min-height: 34px;
      text-align: justify;
    }
  }
}
</style>